import React, {Component} from 'react';

export default class Footer extends Component {
	render() {
		return (
			<div className="bg-sub fixed left-0 bottom-0 w-full p-1.5 m-0 text-left">
				<div className="inline-block">
					This website is a work in progess and is still in development. Thank you for visiting!
				</div>

				<img className="h-16 w-16 float-right" src="/images/megadean_helmet.png" alt="Mega Dean"></img>
			</div>
		);
	}
}