import React, {Component, lazy, Suspense} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const Home = lazy(() => import('./Home'));
const Personal = lazy(() => import('./Personal'));
const Professional = lazy(() => import('./Professional'));
const GameReviews = lazy(() => import('./GameReviews'));

export default class App extends Component {
  render() {
    return (
      <Router>
        <div className="App p-4">
          <Header text="Dean Charles Arnold"/>

          <div className="pb-[60px]">
            <Suspense fallback={<div></div>}>
              <Switch>
                <Route path="/personal">
                  <Personal />
                </Route>

                <Route path="/professional">
                  <Professional />
                </Route>

                <Route path="/reviews">
                  <GameReviews />
                </Route>

                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </Suspense>
          </div>

          <Footer />
        </div>
      </Router>
      
    );
  }
}
