import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class header extends Component {
	render() {
		return (
			<Link to="/">
				<h1 className="text-white text-center text-4xl font-bold underline border-solid border-4 border-sub m-auto p-2.5 w-6/12 hover:border-white">
					{this.props.text}
				</h1>
			</Link>
		);
	}
}